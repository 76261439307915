<template>
  <div class="templateChange app-container">
    <header class="message-title">
      <h2>{{ type === 'add' ? '添加' : '编辑' }}消息模板</h2>
    </header>
    <BaseForm style="width: 50%" label-width="120px" :cols="formField" :form="query" ref="templateForm">
      <template #isEnabledTypeSlot>
        <el-switch
          v-model="query.isEnabled"
          active-color="#3841DB"
          inactive-color="#909399"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </template>
      <template #messageTypeSlot="{ scoped: { prop, placeholder } }">
        <el-select v-model="query[prop]" clearable :placeholder="placeholder">
          <el-option
            v-for="{ itemValue, id, itemName } in messageType"
            :key="id"
            :value="Number(itemValue)"
            :label="itemName"
          ></el-option>
        </el-select>
      </template>
      <template #businessTypeSlot="{ scoped: { prop, placeholder } }" v-if="query.messageType === 1">
        <el-select v-model="query[prop]" clearable :placeholder="placeholder">
          <el-option
            v-for="{ itemName, itemValue, id } in businessOption"
            :key="id"
            :label="itemName"
            :value="Number(itemValue)"
          ></el-option>
        </el-select>
      </template>
      <template #templateTypeSlot="{ scoped: { prop, placeholder } }" v-else>
        <el-select v-model="query[prop]" clearable :placeholder="placeholder">
          <el-option
            v-for="{ itemName, itemValue, id } in templateOption"
            :key="id"
            :label="itemName"
            :value="Number(itemValue)"
          ></el-option>
        </el-select>
      </template>

      <template #contextSlot="{ scoped: { prop, placeholder } }">
        <el-input v-model="query[prop]" type="textarea" :placeholder="placeholder" :rows="3"></el-input>
      </template>
      <template #operationBtns>
        <div class="button-position">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="submitHandle" :loading="loading">保存</el-button>
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { formField } from './formFields'
import { createTemplate, getTemplateById, updateTemplate } from '@/api/messageApi'
import { getDicList, getDetailList } from '@/api/dicApi'
import { deepClone } from '@/components/avue/utils/util'
export default {
  components: {},
  props: {},
  data() {
    return {
      type: '',
      formField,
      templateOption: [],
      businessOption: [],
      loading: false,
      query: {
        isEnabledType: 1,
        messageType: 1,
        businessType: 1,
        templateType: 1
      },
      editForm: {},
      id: '',
      messageType: []
    }
  },
  computed: {},
  watch: {
    'query.messageType': {
      handler(newVal) {
        console.log(this.query)
        if (newVal === 1) {
          this.formField.forEach((item) => {
            if (item.prop === 'businessType') {
              item.hidden = false
            }
            if (item.prop === 'templateType') {
              item.hidden = true
            }
          })
        } else {
          this.formField.forEach((item) => {
            if (item.prop === 'businessType') {
              item.hidden = true
            }
            if (item.prop === 'templateType') {
              item.hidden = false
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 确认按钮
    async submitHandle() {
      try {
        await this.$refs['templateForm'].validate()
      } catch {
        return false
      }
      if (this.type === 'add') {
        this.createTemplateHandle()
      } else {
        this.updateTemplateHandle()
      }
    },
    // 添加模板
    async createTemplateHandle() {
      const newQuery = deepClone(this.query)
      if (newQuery.messageType === 1) {
        delete newQuery.templateType
      } else {
        delete newQuery.businessType
      }
      this.loading = true
      const { code } = await createTemplate(newQuery)
      if (code === 0) {
        this.$message.success('添加成功')
        this.$router.back()
      } else {
        this.$message.error('添加失败')
      }
      this.loading = false
    },
    // 取消
    cancel() {
      this.$router.back()
    },
    // 根据ID获取模板信息
    async getTemplateHandle() {
      if (this.type === 'edit') {
        const { code, detail } = await getTemplateById(this.id)
        if (code === 0) {
          this.editForm = detail
          this.query = deepClone(this.editForm)
        } else {
          this.$message.error('获取模板数据失败')
        }
      }
      return
    },
    // 获取状态和id
    getStatusAndId() {
      this.type = this.$route.query.type
      if (this.type === 'edit') {
        this.id = this.$route.query.id
      }
      return
    },
    // 编辑模板
    async updateTemplateHandle() {
      const newQuery = deepClone(this.query)
      if (newQuery.messageType === 1) {
        delete newQuery.templateType
      } else {
        delete newQuery.businessType
      }
      this.loading = true
      const { code } = await updateTemplate(newQuery)
      if (code === 0) {
        this.$message.success('编辑成功')
        this.$router.back()
      } else {
        this.$message.error('编辑失败')
      }
      this.loading = false
    },
    // 获取消息类型 具体类型中的tmpelateType 和 businessType
    async getMessageType() {
      try {
        const { detail: list } = await getDicList({ page: { pageSize: 0, pageIndex: 1 } })
        // 获取消息类型
        const messageType = list.filter(({ dictType }) => {
          return dictType === 'messageType'
        })
        const { detail: messageDetailList } = await getDetailList({
          dictId: messageType[0].id,
          page: { pageIndex: 1, pageSize: 0 }
        })
        this.messageType = messageDetailList
        // 获取 具体类型中的  businessType
        const businessType = list.filter(({ dictType }) => {
          return dictType === 'businessType'
        })
        const { detail: businessDetailList } = await getDetailList({
          dictId: businessType[0].id,
          page: { pageIndex: 1, pageSize: 0 }
        })
        this.businessOption = businessDetailList
        // 获取 具体类型中的 templateType
        const templateType = list.filter(({ dictType }) => {
          return dictType === 'templateType'
        })
        console.log('111')
        const { detail: templateDetailList } = await getDetailList({
          dictId: templateType[0].id,
          page: { pageIndex: 1, pageSize: 0 }
        })
        this.templateOption = templateDetailList
        console.log('templateDetailList', templateDetailList)
      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
    this.getStatusAndId()
    this.getTemplateHandle()
    // 获取消息类型
    this.getMessageType()
  }
}
</script>

<style scoped lang="scss">
.templateChange {
  padding: 30px;
  .message-title {
    h2 {
      font-weight: 400;
    }
  }
  ::v-deep .el-textarea__inner {
    resize: none;
  }
  .button-position {
    display: inline-block;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
