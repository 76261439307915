var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templateChange app-container"},[_c('header',{staticClass:"message-title"},[_c('h2',[_vm._v(_vm._s(_vm.type === 'add' ? '添加' : '编辑')+"消息模板")])]),_c('BaseForm',{ref:"templateForm",staticStyle:{"width":"50%"},attrs:{"label-width":"120px","cols":_vm.formField,"form":_vm.query},scopedSlots:_vm._u([{key:"isEnabledTypeSlot",fn:function(){return [_c('el-switch',{attrs:{"active-color":"#3841DB","inactive-color":"#909399","active-value":1,"inactive-value":0},model:{value:(_vm.query.isEnabled),callback:function ($$v) {_vm.$set(_vm.query, "isEnabled", $$v)},expression:"query.isEnabled"}})]},proxy:true},{key:"messageTypeSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-select',{attrs:{"clearable":"","placeholder":placeholder},model:{value:(_vm.query[prop]),callback:function ($$v) {_vm.$set(_vm.query, prop, $$v)},expression:"query[prop]"}},_vm._l((_vm.messageType),function(ref){
var itemValue = ref.itemValue;
var id = ref.id;
var itemName = ref.itemName;
return _c('el-option',{key:id,attrs:{"value":Number(itemValue),"label":itemName}})}),1)]}},(_vm.query.messageType === 1)?{key:"businessTypeSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-select',{attrs:{"clearable":"","placeholder":placeholder},model:{value:(_vm.query[prop]),callback:function ($$v) {_vm.$set(_vm.query, prop, $$v)},expression:"query[prop]"}},_vm._l((_vm.businessOption),function(ref){
var itemName = ref.itemName;
var itemValue = ref.itemValue;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":itemName,"value":Number(itemValue)}})}),1)]}}:{key:"templateTypeSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-select',{attrs:{"clearable":"","placeholder":placeholder},model:{value:(_vm.query[prop]),callback:function ($$v) {_vm.$set(_vm.query, prop, $$v)},expression:"query[prop]"}},_vm._l((_vm.templateOption),function(ref){
var itemName = ref.itemName;
var itemValue = ref.itemValue;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":itemName,"value":Number(itemValue)}})}),1)]}},{key:"contextSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-input',{attrs:{"type":"textarea","placeholder":placeholder,"rows":3},model:{value:(_vm.query[prop]),callback:function ($$v) {_vm.$set(_vm.query, prop, $$v)},expression:"query[prop]"}})]}},{key:"operationBtns",fn:function(){return [_c('div',{staticClass:"button-position"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submitHandle}},[_vm._v("保存")])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }