import { requireFun } from '@/utils'
import { is4And20char, validNumber, is1And100char } from '@/utils/validate'
export const formField = [
  {
    label: '消息模板名称',
    prop: 'templateName',
    placeholder: '请输入消息模板名称',
    rule: [
      {
        required: true,
        message: requireFun('消息模板名称')
      },
      {
        validator: is4And20char
      }
    ]
  },
  {
    label: '消息模板编码',
    prop: 'templateCode',
    placeholder: '请输入消息模板编码',
    rule: [
      {
        required: true,
        message: requireFun('消息模板编码')
      },
       {
        validator: validNumber
      },
      {
        validator: is4And20char
      }
    ]
  },
  {
    label: '触发条件名称',
    prop: 'sceneDesc',
    placeholder: '请输入触发条件名称',
    rule: [
      {
        required: true,
        message: requireFun('触发条件名称')
      }
    ]
  },
  {
    label: '触发条件编码',
    prop: 'sceneCode',
    placeholder: '请输入触发条件编码',
    rule: [
      {
        required: true,
        message: requireFun('触发条件编码')
      },
      {
        validator: validNumber
      },
    ]
  },
  { label: '模板状态', prop: 'isEnabled', slotName: 'isEnabledTypeSlot' },
  {
    label: '消息类型',
    prop: 'messageType',
    slotName: 'messageTypeSlot',
    placeholder: '选择消息类型',
    rule: [
      {
        required: true,
        message: requireFun('消息类型')
      }
    ]
  },
  {
    label: '具体类型',
    prop: 'templateType',
    slotName: 'templateTypeSlot',
    placeholder: '选择具体类型',
    rule: [
      {
        required: true,
        message: requireFun('具体类型')
      }
    ],
    hidden: true
  },
  {
    label: '具体类型',
    prop: 'businessType',
    slotName: 'businessTypeSlot',
    placeholder: '选择具体类型',
    rule: [
      {
        required: true,
        message: requireFun('具体类型')
      }
    ],
    hidden: true
  },
  {
    label: '消息内容',
    prop: 'context',
    slotName: 'contextSlot',
    placeholder: '请输入消息内容',
    rule: [
      {
        required: true,
        message: requireFun('消息内容')
      },{
        validator:is1And100char
      }
    ]
  }
]
export const templateOption = [
  { label: '短信通知', value: 1 },
  { label: '验证码', value: 2 },
  { label: '推广信息', value: 3 }
]
export const businessOption = [
  { label: '系统消息', value: 1 },
  { label: '平台消息', value: 2 },
  { label: '服务消息', value: 3 },
  { label: '物流消息', value: 4 },
  { label: '财务消息', value: 5 },
  { label: '订单消息', value: 6 },
  { label: '支付消息', value: 7 },
  { label: '产品消息', value: 8 }
]
